// page init

$(document).on('ready',function () {

	toggleMobileNav();
	// initSelectReplacement();
	initNavScrolling();
	navSearchFocus();
	initCheckboxReplacement();
	initSearchHideNav();
	initFooterWatch();

function navSearchFocus() {
	var search = $('.nav-search').find('input[type="search"]');
	var submit = $('.nav-search').find('button[type="submit"]');
	search.on('focus', function(){
		submit.fadeIn(200);
	});
	search.on('focusout', function() {
		submit.fadeOut(200);
	});
}

function initCheckboxReplacement() {	
	$('input').iCheck({
    checkboxClass: 'icheckbox_square-red',
    radioClass: 'iradio_square-red'
  });
}

// mob menu
function toggleMobileNav() {
	$('.nav-opener, .mob-blackout').click(function(){
			$('.nav-opener').toggleClass('open');
			$('.navigation').toggleClass('open');
			$('#wrapper.main-wrapper').toggleClass('open');
			$('.mob-blackout').fadeToggle();
	});
}

});

function initNavScrolling() {
	var outer_nav = $('.navigation');
	var total_nav = $('nav#nav');
	var page_nav = $('.sub-level-nav');
	var dropdown = $('.sub-level-nav .sub-nav');
	var page_nav_height = page_nav.outerHeight();
	var total_nav_height = total_nav.outerHeight();
	var window_height = $(window).innerHeight();
	var nav_item = $('.nav-item');

	nav_item.hover(function(){
		outer_nav.addClass('expanded');
	}, function(){
		outer_nav.removeClass('expanded');
	});

	dropdown.css({
		'min-height': page_nav_height + 'px',
		'top': (page_nav.position().top + $('.nav-area').position().top),
	})

	if (total_nav_height > window_height) {
		dropdown.addClass('full-height');
	} else {
		dropdown.removeClass('full-height');
	}

	$(window).resize(function(){
		var window_height = $(window).innerHeight();
		if (total_nav_height > window_height) {
			dropdown.addClass('full-height');
		} else {
			dropdown.removeClass('full-height');
		}
	});
}


// function initSelectReplacement() {
// 	$("select").select2({
// 	  minimumResultsForSearch: Infinity
// 	});
// }

function ticker() {
	var ticker = $('.ticker');
	var elems = $('.ticker').length - 1;
	var container = $('.related-links-container');
	function rotateFade() {
		ticker.each(function (i, item){
			var heading_height = $(container).find('h2').outerHeight(true);
			var container_height = $(this).outerHeight(true) + heading_height + 30;
			setTimeout(function(){
				container.animate({
					height: container_height
				}, 300);
				$(item).delay(300).fadeIn(400).delay(5000).fadeOut(400, function(){
					if (elems == i) rotateFade();
				});
			}, 6100 * i);
		});
	}
	rotateFade();
}


function initSearchHideNav() {
	var searchButton = $('.o-header__toggle--search > button');
	searchButton.on('click', function() {
		if ($('.navigation').hasClass('hidenav')) {
			$('.navigation').removeClass('hidenav');
			$('.nav-opener').css('display', 'none');
		}
		else {
			$('.navigation').addClass('hidenav');
			$('.nav-opener').css('display', 'block');
		}
	});
}

function initFooterWatch() {
	let footer = $('footer');
	let branchNav = $('.navigation');
	let wrapper = $('#wrapper');

	let observer = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			// console.log(entry.intersectionRatio);
			// console.log(branchNav.css('left'));
			// console.log('branchnav left !== -300px');
			// console.log(branchNav.css('left') !== '-300px');
			// console.log('ration > 0 =');
			// console.log(entry.intersectionRatio > 0);
			if ((entry.intersectionRatio > 0) ) {
				branchNav.addClass('hidenav');
				wrapper.addClass('showNavBurger')
			}
			else {
				branchNav.removeClass('hidenav');
				wrapper.removeClass('showNavBurger')
			}
		})
	})

	observer.observe(footer[0])
}